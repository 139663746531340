import { FunctionComponent, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import MotionSection from "../../components/pageViews/MotionSection";
import SampleViewOne from "../../components/pageViews/SampleViewOne";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import kitchenImage1 from "../../assets/image/kitchen/N5108973_14.jpg";
import kitchenImage2 from "../../assets/image/kitchen/N5108973_17.jpg";
import kitchenImage3 from "../../assets/image/kitchen/more/C5479474_8.jpg";
import kitchenImage4 from "../../assets/image/kitchen/more/C5479474_9.jpg";
import kitchenImage5 from "../../assets/image/kitchen/more/C5744904_14.jpg";
import kitchenImage6 from "../../assets/image/kitchen/more/C5744904_16.jpg";
import kitchenImage7 from "../../assets/image/kitchen/more/C5744904_17.jpg";
import kitchenImage8 from "../../assets/image/kitchen/more/N5108973_11.jpg";
import kitchenImage9 from "../../assets/image/kitchen/more/N5108973_12.jpg";
import kitchenImage10 from "../../assets/image/kitchen/more/N5108973_13.jpg";
import kitchenImage11 from "../../assets/image/kitchen/more/N5108973_16.jpg";
import kitchenImage12 from "../../assets/image/kitchen/more/e1b8a_13.jpg";
import kitchenImage13 from "../../assets/image/kitchen/more/e1b8a_15.jpg";
import kitchenImage14 from "../../assets/image/kitchen/more/e1b8a_16.jpg";
import kitchenImage15 from "../../assets/image/kitchen/more/e1b8a_17.jpg";
import kitchenImage16 from "../../assets/image/kitchen/more/e1b8a_21.jpg";
import kitchenImage17 from "../../assets/image/kitchen/more/e1b8a_21.jpg";
import kitchenImage18 from "../../assets/image/kitchen/more/e1b8a_22.jpg";
import kitchenImage19 from "../../assets/image/kitchen/more/e1b8a_24.jpg";
import kitchenImage20 from "../../assets/image/kitchen/more/e1b8a_25.jpg";
import kitchenImage21 from "../../assets/image/kitchen/more/e1b8a_3.jpg";
import kitchenImage22 from "../../assets/image/kitchen/more/e1b8a_4.jpg";
import TopRight from "../../components/pageViews/topright";

interface KitchenProps {}

const Kitchen: FunctionComponent<KitchenProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
    <div className="hidden lg:block">
      <ReactPageScroller {...pageScrollerProps}>
        <MotionSection handlePageChange={handlePageChange} title={"Kitchen"} />
        <SampleViewOne
          title={"Kitchen"}
                      content={[
                      "The Kitchen is inarguably the heart of the home. Where one's culinary dreams come to life. It isn't just a place to cook; the kitchen is an area of creativity, of family gatherings, and of fond memories. Our Kitchen designs offer bespoke kitchen solutions that gracefully blend style and functionality, offering innovative and unique solutions to elevate this space and truly showcase that it is the heart of your home."]
          }
                      contents={[<ul>At Square Studio Homes, our services go beyond aesthetics, we delve into the functionality
and the compatibility of the kitchen space with its owners. Our range of services include, but
                          are not limited to:</ul>, <br />, <li><strong>Custom cabinetry:</strong> Everyone deserves a kitchen that is tailor made for them. Dive into
                              the luxury of custom cabinets that optimize storage and are made especially for your
                              unique space.</li>, <li><strong>Smart kitchen solutions:</strong> Hoping for a timeless look for your kitchen which also
                                  includes the convenience of smart technologies? Our expert teams are well
                                  experienced in the art of integrating smart solutions to homes, providing efficiency at
                                  your fingertips.</li>,]}
          img={[kitchenImage1, kitchenImage2]}
        />
         <TopRight
            title={"Kitchen"}
            content={
                [`At our curated portfolio featuring original kitchen designs. 
              Our expert team specializes in fashioning a customized kitchen that caters to your lifestyle as well as suits your aesthetic.`]
            }
                      contents={
                          [<li><strong>Layout optimization:</strong> We believe that one should not be limited by area. Our experts analyze and optimize kitchen layouts according to your home's floor plan, maximizingspace and ensuring convenience.</li>, <br></br>,
                          <li><strong>Sustainable practices:</strong> What's better than a sustainable kitchen? We incorporate eco-friendly materials, energy efficient appliances, and waste reducing solutions into our designs. Introduce sustainability into your everyday life with our sustainable kitchen solutions.</li>,
                          ]}
            img={[kitchenImage1, kitchenImage2]}
          />
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
            title={" More Cabinet Designs"}
          dialogTitle={"Cabinet"}
          img={[
            kitchenImage3,
            kitchenImage4,
            kitchenImage5,
            kitchenImage6,
            kitchenImage7,
            kitchenImage8,
            kitchenImage9,
            kitchenImage10,
            kitchenImage11,
            kitchenImage12,
            kitchenImage13,
            kitchenImage14,
            kitchenImage15,
            kitchenImage16,
            kitchenImage17,
            kitchenImage18,
            kitchenImage19,
            kitchenImage20,
            kitchenImage21,
            kitchenImage22,
          ]}
          content={[
            "sadasdasd ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
          contents={[
            "sadasdasd ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        />
        {/* <AboutUs> */}

        {/* </AboutUs> */}
      </ReactPageScroller>
    </div>
    <div className="block lg:hidden">
       <MotionSection handlePageChange={handlePageChange} title={"Kitchen"} />
        <SampleViewOne
          title={"Kitchen"}
                  content={[
                      "The Kitchen is inarguably the heart of the home. Where one's culinary dreams come to life. It isn't just a place to cook; the kitchen is an area of creativity, of family gatherings, and of fond memories. Our Kitchen designs offer bespoke kitchen solutions that gracefully blend style and functionality, offering innovative and unique solutions to elevate this space and truly showcase that it is the heart of your home."]
                  }
                  contents={[<ul>At Square Studio Homes, our services go beyond aesthetics, we delve into the functionality
                      and the compatibility of the kitchen space with its owners. Our range of services include, but
                      are not limited to:</ul>, <li><strong>Custom cabinetry:</strong> Everyone deserves a kitchen that is tailor made for them. Dive into
                          the luxury of custom cabinets that optimize storage and are made especially for your
                          unique space.</li>, <li><strong>Smart kitchen solutions:</strong> Hoping for a timeless look for your kitchen which also
                              includes the convenience of smart technologies? Our expert teams are well
                              experienced in the art of integrating smart solutions to homes, providing efficiency at
                              your fingertips.</li>,]}
          img={[kitchenImage1, kitchenImage2]}
        />
              <TopRight
                  title={"Kitchen"}
                  content={
                      [`At our curated portfolio featuring original kitchen designs. Our expert team specializes in fashioning a customized kitchen that caters to your lifestyle as well as suits your aesthetic.`]
                  }
                  contents={
                      [<li><strong>Layout optimization:</strong> We believe that one should not be limited by area. Our experts analyze and optimize kitchen layouts according to your home's floor plan, maximizingspace and ensuring convenience.</li>,<br></br>,
                          <li><strong>Sustainable practices:</strong> What's better than a sustainable kitchen? We incorporate eco-friendly materials, energy efficient appliances, and waste reducing solutions into our designs. Introduce sustainability into your everyday life with our sustainable kitchen solutions.</li>,
                      ]}
                  img={[kitchenImage1, kitchenImage2]}
              />
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More Cabinet design"}
          dialogTitle={"Cabinet"}
          img={[
            kitchenImage3,
            kitchenImage4,
            kitchenImage5,
            kitchenImage6,
            kitchenImage7,
            kitchenImage8,
            kitchenImage9,
            kitchenImage10,
            kitchenImage11,
            kitchenImage12,
            kitchenImage13,
            kitchenImage14,
            kitchenImage15,
            kitchenImage16,
            kitchenImage17,
            kitchenImage18,
            kitchenImage19,
            kitchenImage20,
            kitchenImage21,
            kitchenImage22,
          ]}
          content={[
            "hooooooooooooooo",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
          contents={[
            "sadasdasd ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        />
    </div>
    </>
  );
};

export default Kitchen;
