import { FunctionComponent, ReactNode, useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import TopCenter from "../../components/pageViews/topCenter";

import TopRight from "../../components/pageViews/topright";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";

import livingRoomImage1 from "../../assets/image/living room/C5479474_11.jpg";
import livingRoomImage2 from "../../assets/image/living room/C5479474_4.jpg";
import livingRoomImage3 from "../../assets/image/living room/C5487872.jpg";
import livingRoomImage4 from "../../assets/image/living room/C5487872_4.jpg";
import livingRoomImage5 from "../../assets/image/living room/C5487872_5.jpg";
import livingRoomImage6 from "../../assets/image/living room/C5487872_6.jpg";
import livingRoomImage7 from "../../assets/image/living room/C5744904_10.jpg";
import livingRoomImage8 from "../../assets/image/living room/C5744904_13.jpg";
import livingRoomImage9 from "../../assets/image/living room/C5744904_4.jpg";
import livingRoomImage10 from "../../assets/image/living room/C5744904_5.jpg";
import livingRoomImage11 from "../../assets/image/living room/C5744904_8.jpg";
import livingRoomImage12 from "../../assets/image/living room/e1b8a_11.jpg";

import FooterMain from "../../components/footer";
interface LivingRoomProps {}

const LivingRoom: FunctionComponent<LivingRoomProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <div className="hidden lg:block">
        <ReactPageScroller {...pageScrollerProps}>
          <TopCenter
            title={"Living Room"}
                      content={["Step into luxury with our Living Room Designs. Our bespoke designs combine sophistication and functionality, to create welcoming and beautiful spaces that cater to all your needs. Iensuring not only style, but also the comfort of smart home solutions at your fingertips."]}
                      contents={["Our Living Room Designs also include storage strategies that cleverly allow room to store all your necessities without compromising on the functionality and aesthetics of the space.Explore our portfolio to discover designs that seamlessly blend elements of style, comfort, technology, and functionality, to create the ultimate space for relaxation."]}
            img={[livingRoomImage3, livingRoomImage4]}
          ></TopCenter>
          <TopRight
            title={"Dining Room"}
                      content={[
                          "Redefine your living space with our exquisite designs and smart technology. Select suitable design for your dream living room from our carefully curated catalog, or delve into the luxury of custom living room designs."]
                      }
                      contents={[<ul>No matter what you decide, when you choose Square Studio
                          Homes, you are choosing:</ul>,<br></br>, <li><strong>Custom cabinetry:</strong> Spoil yourself with our bespoke designs created especially
                              for your comfort. Our living rooms include posh furnishings, personalized layouts, and
                              contemporary home decor, crafting a unique space that caters to your every need.</li>, <li><strong>Smart Entertainment:</strong> Who doesn't appreciate a smart home? We seamlessly
                                  integrate technology into a beautiful living space to build a smart and stylish
                                  entertainment hub that serves as the ultimate relaxation area.</li>,]}
            img={[livingRoomImage1, livingRoomImage2]}
          />
          <MoreDetailsSlider
            onSlideChange={handleSwiperSlideChange}
            title={" More LivingRoom design"}
            dialogTitle={"Living Room"}
            img={[
              livingRoomImage5,
              livingRoomImage6,
              livingRoomImage7,
              livingRoomImage8,
              livingRoomImage9,
              livingRoomImage10,
              livingRoomImage11,
              livingRoomImage12,
              livingRoomImage9,
              livingRoomImage10,
              livingRoomImage11,
              livingRoomImage12,
              livingRoomImage9,
              livingRoomImage10,
              livingRoomImage11,
              livingRoomImage12,
            ]}
            content={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
                 contents={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
          >
            <FooterMain />
          </MoreDetailsSlider>
        </ReactPageScroller>
      </div>
      <div className="block lg:hidden">
        <TopCenter
          title={"Living Room"}
                  content={["Step into luxury with our Living Room Designs. Our bespoke designs combine sophistication and functionality, to create welcoming and beautiful spaces that cater to all your needs."]}
                  contents={["Our Living Room Designs also include storage strategies that cleverly allow room to store all your necessities without compromising on the functionality and aesthetics of the space.Explore our portfolio to discover designs that seamlessly blend elements of style, comfort, technology, and functionality, to create the ultimate space for relaxation. "]}
          img={[livingRoomImage3, livingRoomImage4]}
        ></TopCenter>
        <TopRight
          title={"Dining Room"}
                  content={[
                      "Redefine your living space with our exquisite designs and smart technology. Select suitable design for your dream living room from our carefully curated catalog, or delve into the luxury of custom living room designs."]
                  }
                  contents={[<ul>No matter what you decide, when you choose Square Studio
                      Homes, you are choosing:</ul>, <li><strong>Custom cabinetry:</strong> Spoil yourself with our bespoke designs created especially
                          for your comfort. Our living rooms include posh furnishings, personalized layouts, and
                          contemporary home decor, crafting a unique space that caters to your every need.</li>, <li><strong>Smart Entertainment:</strong> Who doesn't appreciate a smart home? We seamlessly
                              integrate technology into a beautiful living space to build a smart and stylish
                              entertainment hub that serves as the ultimate relaxation area.</li>,]}
          img={[livingRoomImage1, livingRoomImage2]}
        />
        <MoreDetailsSlider
          onSlideChange={handleSwiperSlideChange}
          title={" More LivingRoom design"}
          dialogTitle={"Living Room"}
          img={[
            livingRoomImage5,
            livingRoomImage6,
            livingRoomImage7,
            livingRoomImage8,
            livingRoomImage9,
            livingRoomImage10,
            livingRoomImage11,
            livingRoomImage12,
            livingRoomImage9,
            livingRoomImage10,
            livingRoomImage11,
            livingRoomImage12,
            livingRoomImage9,
            livingRoomImage10,
            livingRoomImage11,
            livingRoomImage12,
          ]}
          content={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
                    contents={[
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
            "visual form of a document or a typeface without relying on me",
            "visual form of a document or a typeface without relying on me",
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </div>
    </>
  );
};

export default LivingRoom;
