import React, { useState } from "react";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import AboutUs from "./aboutus";
import WhyUs from "./whyUs";
import Awards from "./ouraward";
import FooterMain from "../../components/footer";

const Index: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  // const handlePageChange = (number: number) => {
  //   setCurrentPage(number);
  // };

  // const handleBeforePageChange = (number: number) => {
  //   console.log(number);
  // };

  // const getPagesNumbers = () => {
  //   const pageNumbers = [];

  //   for (let i = 1; i <= 5; i++) {
  //     pageNumbers.push(
  //       <button key={i} onClick={() => handlePageChange(i)}>
  //         {i}
  //       </button>
  //     );
  //   }

  //   return [...pageNumbers];
  // };

  // const pagesNumbers = getPagesNumbers();

  const pageScrollerProps: ReactPageScrollerProps = {
    // pageOnChange: handlePageChange,
    // onBeforePageScroll: handleBeforePageChange,
    // customPageNumber: currentPage,
    animationTimer: 1000,
  };

  return (
    <>
      <section className="block lg:hidden">
        {/* <ReactPageScroller {...pageScrollerProps}> */}
          <AboutUs />
          <WhyUs />
          <Awards />
              <section className="relative z-0 w-screen h-screen bg-black lg:hidden  :h-[45vx] mt-[-120%]	">
            <FooterMain classNameprops=""/>
          </section>
        {/* </ReactPageScroller> */}
      </section>
      <section className="hidden lg:block ">
        <ReactPageScroller {...pageScrollerProps}>
          <AboutUs />
          <WhyUs />
          <Awards>
          <FooterMain classNameprops="bottom-[130px]" />
          </Awards>
        </ReactPageScroller>
      </section>
      {/* <div className="fixed z-40 flex justify-center mx-auto pagination-container bottom-4 w-28">
        {pagesNumbers}
      </div> */}
    </>
  );
};

export default Index;
