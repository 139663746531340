import { FunctionComponent, ReactNode, useState } from "react";
import TopCenter from "../../components/pageViews/topCenter";

import TopRight from "../../components/pageViews/topright";
import ReactPageScroller, { ReactPageScrollerProps } from "react-page-scroller";
import BottomLeft from "../../components/pageViews/bottomLeft";
import MoreDetailsSlider from "../../components/pageViews/moreDetailsSlider";
import FooterMain from "../../components/footer";

import bedroom1 from "../../assets/image/bedroom/C5487872_16.jpg";
import bedroom2 from "../../assets/image/bedroom/C5744904_19.jpg";
import bedroom3 from "../../assets/image/bedroom/e1b8a_34.jpg";

interface BedroomPageProps {}

const BedroomPage: FunctionComponent<BedroomPageProps> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableScrolling, setDisableScrolling] = useState(false);
  // useEffect(()=>{
  //   console.log("useeefect",disableScrolling);

  // },[disableScrolling])
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setDisableScrolling(false);
    // console.log("pageScroll",disableScrolling);
  };
  const handleSwiperSlideChange = (swiper: any) => {
    setCurrentPage(currentPage);
    setDisableScrolling(true);
    // console.log("swipper",disableScrolling);
  };
  const pageScrollerProps: ReactPageScrollerProps = {
    pageOnChange: handlePageChange,
    customPageNumber: currentPage,
    animationTimerBuffer: 1000,
    animationTimer: 1000,
    renderAllPagesOnFirstRender: true,
    blockScrollUp: disableScrolling,
    blockScrollDown: disableScrolling,
  };
  return (
    <>
      <div className="hidden lg:block text-justif">
        <ReactPageScroller {...pageScrollerProps}>
          <TopCenter
            title={"BEDROOM"}
            content={[
                      "The bedroom is always one's safe space, a personal haven, in their home. We believe that everyone deserves a bedroom that also serves as their refuge, where one can unwind and relax no matter what kind of day they've had. With Square Studio Homes, you can transform your bedroom into a sanctuary of tranquility"
            ]}
             contents={
              "Our bedroom designs blend comfort and style, creating personalized retreats that are modified to your specific tastes. Discover luxurious textures, extensive color palettes, and efficient space solutions with our bedroom designs. Whether you prefer a timeless classic, modern interiors, contemporary artsy, or a cozy haven, you will find that our team is well equipped to cater to diverse tastes. Explore how we bring your dreams to life with our bespoke bedroom designs."
            }
            img={[bedroom1]}
          />
          <TopRight
            title={"BEDROOM"}
            content={[
                      "Our bedroom designs are efficient and convenient, and every detail is meticulously craftedfor your comfort."
            ] }
                      contents={[<ul>Some of our services include:
                      </ul>, <br />, <li><strong>Smart storage solutions:</strong> Maximize your space with intelligent storage designs.
                          Our customized storage solutions ensure a clutter-free environment while keeping everything important
                          within reach.
                          </li>, <li><strong>Personalized ambience:</strong> Manage the mood with custom lighting solutions and home
                              decor that adapt to your various relaxation needs.</li>, <li><strong> Timeless aesthetics:</strong> Whether your preference is a modern retreat, contemporary
                                  meets classic, or timeless tradition, our special bedroom designs ensure an aesthetic
                          that stands the test of time and trends.Manage the mood with custom lighting solutions and home
                                  decor that adapt to your various relaxation needs.</li>,]}
            img={[bedroom2]}
          />
          {/* <BottomLeft
            title={"BEDROOM3"}
            content={
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
            }
            img={[bedroom3]}
          /> */}

          <MoreDetailsSlider
            title={" More bedroom design"}
            onSlideChange={handleSwiperSlideChange}
            dialogTitle={"bedroom"}
            img={[bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3]}
            content={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
                  contents={[
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
              "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used ",
              "visual form of a document or a typeface without relying on me",
              "visual form of a document or a typeface without relying on me",
            ]}
          >
            <FooterMain />
          </MoreDetailsSlider>
        </ReactPageScroller>
      </div>
      <div className="block lg:hidden">
        <TopCenter
                  title={"BEDROOM"}
                  content={[
                      "The bedroom is always one's safe space, a personal haven, in their home. We believe that everyone deserves a bedroom that also serves as their refuge, where one can unwind and relax no matter what kind of day they've had."
                  ]}
                  contents={
                      "Our bedroom designs blend comfort and style, creating personalized retreats that are modified to your specific tastes. Discover luxurious textures, extensive color palettes, and efficient space solutions with our bedroom designs. Whether you prefer a timeless classic, modern interiors, contemporary artsy, or a cozy haven, you will find that our team is well equipped to cater to diverse tastes. Explore how we bring your dreams to life with our bespoke bedroom designs."
                  }
          img={[bedroom1]}
        />
        <TopRight
          title={"BEDROOM"}
                  content={[
                      "Our bedroom designs are efficient and convenient, and every detail is meticulously craftedfor your comfort."
                  ]}
                  contents={[<ul>Some of our services include:
                  </ul>, <li><strong>Smart storage solutions:</strong> Maximize your space with intelligent storage designs.
                      Our customized storage solutions ensure a clutter-free environment while keeping everything important
                      within reach.
                  </li>, <li><strong>Personalized ambience:</strong> Manage the mood with custom lighting solutions and home
                      decor that adapt to your various relaxation needs.</li>, <li><strong> Timeless aesthetics:</strong> Whether your preference is a modern retreat, contemporary
                          meets classic, or timeless tradition, our special bedroom designs ensure an aesthetic
                          that stands the test of time and trends.Manage the mood with custom lighting solutions and home
                          decor that adapt to your various relaxation needs.</li>,]}
          img={[bedroom2]}
        />
        {/* <BottomLeft
          title={"BEDROOM3"}
          content={
            "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content... "
          }
          img={[bedroom3]}
        /> */}
        <MoreDetailsSlider
          title={" More bedroom design"}
          onSlideChange={handleSwiperSlideChange}
          dialogTitle={"bedroom"}
          img={[bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3,bedroom1, bedroom2, bedroom3]}
          content={[
                       "The bedroom is always one�s safe space, a personal haven, in their home. We believe that everyone deserves a bedroom that also serves as their refuge, where one can unwind and relax no matter what kind of day they�ve had. With Square Studio Homes, you can transform your bedroom into a sanctuary of tranquility. Our bedroom designs blend comfort and style, creating personalized retreats that are modified to your specific tastes. Discover luxurious textures, extensive color palettes, and efficient space solutions with our bedroom designs."
          ]}
             contents={[
                       "The bedroom is always one�s safe space, a personal haven, in their home. We believe that everyone deserves a bedroom that also serves as their refuge, where one can unwind and relax no matter what kind of day they�ve had. With Square Studio Homes, you can transform your bedroom into a sanctuary of tranquility. Our bedroom designs blend comfort and style, creating personalized retreats that are modified to your specific tastes. Discover luxurious textures, extensive color palettes, and efficient space solutions with our bedroom designs."
          ]}
        >
          <FooterMain />
        </MoreDetailsSlider>
      </div>
    </>
  );
};

export default BedroomPage;
